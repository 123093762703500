<template>
    <v-list>
        <v-list-item v-for="(option,i) in options" :key="i" link :to="{name: option.to}">
            <v-list-item-content>
                <v-list-item-title v-text="option.text"/>
            </v-list-item-content>

            <v-list-item-icon>
                <v-chip color="ternary" class="px-6" label>{{ option.count }}</v-chip>
            </v-list-item-icon>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: "MenuHome",
    props: {
        options: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style scoped>

</style>