<template>
    <master-panel>
        <template v-slot:body>
            <loading-spinner v-if="loading" line height="10px"/>
            <menu-home :options="options"/>
        </template>
        <template v-slot:buttons>
            <logout-button/>
        </template>
    </master-panel>
</template>

<script>

import MenuHome from "@/components/lists/MenuHome";
import LogoutButton from "@/components/common/LogoutButton";
import MasterPanel from "@/components/layouts/MasterPanel";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import httpService from "@/services/httpService";

export default {
    name: "Home",
    components: {LoadingSpinner, MasterPanel, LogoutButton, MenuHome},
    data() {
        return {
            loading: false,
            options: [
                {text: 'Candidatos a cirugía', to: 'secretary.operations', count: 0},
                {text: 'Candidatos a tratamiento médico', to: 'secretary.medical-treatments', count: 0}
            ]
        }
    },
    computed: {
        doctorId(vm = this) {
            return vm.currentUser?.doctor_id || null
        }
    },
    async mounted() {
        const vm = this
        await vm.loadDashboard()
    },
    methods: {
        async loadDashboard() {
            const vm = this
            vm.loading = true
            let response = await httpService.get(`doctor/${vm.doctorId}/dashboard`)
            // console.log('loadDashboard', response)
            vm.options[0].count = response.data.operations || 0
            vm.options[1].count = response.data.medical_treatments || 0
            vm.loading = false
        }
    },
}
</script>

<style scoped>

</style>
